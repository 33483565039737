export const company_banner = {
    create: {
      url: "companybanner/create",
      method: "POST",
      data: null,
    },
    update: {
      url: "companybanner/update/",
      method: "PUT",
      id: null,
      data: null,
    },
    get: {
      url: "companybanner/get",
      method: "GET",
      id: null,
      params: null,
    },
    delete: {
      url: "companybanner/delete/",
      method: "DELETE",
      id: null,
    },
    
  };
  